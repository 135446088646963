import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FaTools, FaShieldAlt, FaWifi, FaBatteryThreeQuarters } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation } from "react-router-dom";

const Os = ({ jsonData }) => {
    const styles = useResponsiveStyles();
    const { t } = useTranslation();
    
    const location = useLocation();
  const { os } = jsonData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
    const handleBlurClick = () => {
      setIsModalOpen(true);
    };
  
    const handlePasswordSubmit = () => {
      setErrorMessage(
        "Senha incorreta. Por favor, entre em contato conosco pelo nosso WhatsApp."
      );
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setPassword("");
      setErrorMessage("");
    };
  

  // Safely format the date if it's valid
  let formattedDate = "Data não disponível";
  if (os.date) {
    try {
      const parsedDate = new Date(os.date.split("/").reverse().join("-"));
      if (!isNaN(parsedDate)) {
        formattedDate = format(parsedDate, "PPP", { locale: ptBR });
      }
    } catch (e) {
      console.error("Error parsing date:", e);
    }
  }

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{os.title || "Título não disponível"}</title>
        <meta name="description" content={os.description || "Descrição não disponível"} />
        <meta name="keywords" content={os.keywords || "Palavras-chave não disponíveis"} />
      </Helmet>

      <h1 style={styles.heading}>{os.title || "Título não disponível"}</h1>
      <div style={styles.containerwhatsapp}>
              <a
                      style={styles.whatsappButton}
                      href={getCompleteWhatsAppNumber(t)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        if (typeof window.gtag === "function") {
                          window.gtag("event", `whatsapp_click_conserto_tela_${jsonData.deviceType}_${jsonData.modelName}`, {
                            event_category: "engagement",
                            event_label: location.pathname,
                            value: 1,
                          });
                        }
                      }}
                    >
                      <i className="fab fa-whatsapp"></i> {t('header.button')}
                    </a>
                    </div>
      
      <p style={styles.description}>{os.comment_clean || "Comentário não disponível"}</p>

      <div style={styles.section}>
      <h2 style={styles.sectionTitle}>Detalhes do Reparo</h2>
      <p
        style={{
          ...styles.description,
          filter: "blur(5px)",
          WebkitFilter: "blur(5px)",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={handleBlurClick}
        title="Clique para desbloquear"
      >
        {os.comment_large || "Detalhes não disponíveis"}
      </p>

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <h3>Digite a senha </h3>
            <h4 style={{ marginBottom: "20px" }}>4 primeiros digitos do CPF + 4 últimos digitos do número de celular</h4>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              style={{
                width: "80%",
                padding: "10px",
                marginBottom: "15px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
            />
            <button
              onClick={handlePasswordSubmit}
              style={{
                padding: "10px 20px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Enviar
            </button>
            {errorMessage && (
              <p
                style={{
                  color: "red",
                  marginTop: "10px",
                  fontSize: "14px",
                }}
              >
                {errorMessage}
              </p>
            )}
             {!errorMessage && (
              <p></p>
            )}
            <button
              onClick={handleCloseModal}
              style={{
                marginTop: "10px",
                backgroundColor: "transparent",
                color: "#007bff",
                border: "none",
                cursor: "pointer",
                fontSize: "14px",
                textDecoration: "underline",
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>


      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Data do Atendimento</h2>
        <p style={styles.description}>{`Data: ${formattedDate}`}</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Cliente</h2>
        <p style={styles.description}>{`Cliente: ${os.client || "Não especificado"}`}</p>
      </div>
    </div>
  );
};

export default Os;
