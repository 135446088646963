import React from "react";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import i18n from "./locales/pt/translation.json";


const Layout = ({ children }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": i18n.header.title,
    "description": i18n.about.description,
    "url": "https://digitalconte.com",
    "telephone": "+5548999089562",
    "logo": "https://digitalconte.com/assets/images/logo-preto-fundo-branco.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": i18n.header.address.split(",")[0],
      "addressLocality": "Florianópolis",
      "addressRegion": "SC",
      "postalCode": i18n.header.address.split(" - ")[2],
      "addressCountry": "BR"
    },
    "openingHours": i18n.header.hours.replace("Segunda a Sexta", "Mo-Fr").replace("e Sábado", ", Sa"),
    "sameAs": [
      "https://www.instagram.com/digitalcontefloripa/",
      "https://maps.google.com/maps?q=Digital%20Conte%20Reparo%20Florianopolis",
      "https://linktr.ee/digitalconteofc"
    ],
    "service": i18n.about.services.map((service) => ({
      "@type": "Service",
      "name": service,
      "description": "Conserto de celular, tablet, smartwatch e informática, marcas Apple, Samsung, Xiaomi e Motorola em Florianópolis - Centro."
    }))
  };

  const styles = {
    wrapper: {
      minHeight: "100vh",
      // display: "flex",
      // flexDirection: "column",
      justifyContent: "space-between"
    },
    // content: {
    //   flex: 1,
    // },
  };

  return (
    <div style={styles.wrapper}>
      <Helmet>
        <title>{i18n.header.title}</title>
        <meta name="description" content={i18n.about.description} />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <div style={styles.content}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
