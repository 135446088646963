import React from "react";
import { Helmet } from "react-helmet";
import { FaCamera, FaTools, FaShieldAlt, FaClock } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { urls } from "./config";
import VariateSection from "./VariateSection"

const ConsertarCamera = ({ jsonData }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dateLanc = format(new Date(jsonData.lancamento.split("/").reverse().join("-")), "PPP", { locale: ptBR });

    const styles = useResponsiveStyles();
    const whatsappLink = getCompleteWhatsAppNumber(t);

    return (
        <div style={styles.container}>
            <Helmet>
                <title>{`Conserto de Câmera ${jsonData.deviceType} ${jsonData.name} | Especialistas em ${jsonData.marca} - Digital Conte Florianópolis`}</title>
                <meta
                    name="description"
                    content={`Especialistas em conserto de câmeras para ${jsonData.name}. Peças originais, técnicos certificados e serviço garantido. Atendemos em Florianópolis. Agende agora mesmo.`}
                />
                <meta
                    name="keywords"
                    content={`conserto de câmera, reparo de câmera, ${jsonData.name}, ${jsonData.marca}, câmera original, Florianópolis, assistência técnica especializada`}
                />
            </Helmet>

            <h1 style={styles.heading}>{`Conserto de Câmera ${jsonData.deviceType} ${jsonData.name}`}</h1>
            <Link to={`/pt${urls.marca}${jsonData.marca.toLowerCase()}`} style={styles.backButton}>
                {t("backButton", `← Voltar para a lista da marca ${jsonData.marca.toLowerCase()}`)}
            </Link>
            <div style={styles.containerwhatsapp}>
                <a
                    style={styles.whatsappButton}
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (typeof window.gtag === "function") {
                            window.gtag("event", `whatsapp_click_conserto_camera_${jsonData.deviceType}_${jsonData.modelName}`, {
                                event_category: "engagement",
                                event_label: location.pathname,
                                value: 1,
                            });
                        }
                    }}
                >
                    <i className="fab fa-whatsapp"></i> {t('header.button')}
                </a>
            </div>

            <p style={styles.description}>
                O <strong>{jsonData.name}</strong>, fabricado pela renomada {jsonData.marca}, é um dispositivo de destaque em qualidade de imagem e vídeo. Lançado em {dateLanc}, este dispositivo apresenta uma câmera principal de resolução {jsonData.cameraPrincipalRes} e gravação de vídeos de alta qualidade ({jsonData.videoPrincipal}). No entanto, problemas na câmera podem impactar significativamente sua experiência de uso.
            </p>

            <p style={styles.description}>
                Na <strong>Digital Conte Florianópolis</strong>, oferecemos diagnósticos e reparos especializados para câmeras danificadas ou com defeito. Nossa equipe altamente qualificada utiliza apenas peças originais e técnicas modernas para restaurar a funcionalidade do seu {jsonData.name}.
            </p>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>Por que consertar a câmera do seu {jsonData.name}?</h2>
                <p style={styles.description}>
                    A câmera do seu dispositivo é essencial para capturar momentos importantes e realizar videoconferências. Se você está enfrentando problemas como imagens desfocadas, mau funcionamento do flash ou falhas na gravação de vídeos, é hora de buscar ajuda profissional. Garantimos um serviço eficiente e confiável.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>Especificações da Câmera</h2>
                <p style={styles.description}>
                    Conheça as especificações da câmera do {jsonData.name} e veja como podemos ajudá-lo a restaurar sua funcionalidade:
                </p>
                <div style={styles.grid}>
              
                    {/* {jsonData.cameraPrincipal && (
                        <div style={styles.differential}>
                            <FaCamera style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Câmera Principal: ${jsonData.cameraPrincipal}`}</p>
                        </div>
                    )} */}
                    {jsonData.cameraPrincipalRes && (
                        <div style={styles.differential}>
                            <FaCamera style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Resolução Camera Principal: ${jsonData.cameraPrincipalRes}`}</p>
                        </div>
                    )}
                    {jsonData.tamanhoSensorPrinc && (  
                        <div style={styles.differential}>
                            <FaCamera style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Tamanho Sensor Principal: ${jsonData.tamanhoSensorPrinc}`}</p>
                        </div>
                    )}
                    {jsonData.cameraFrontalRes && (
                        <div style={styles.differential}>
                            <FaCamera style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Câmera Frontal: ${jsonData.cameraFrontalRes}`}</p>
                        </div>
                    )}
                    {jsonData.tamanhoSensorFrontal && (
                        <div style={styles.differential}>
                            <FaCamera style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Tamanho Sensor Frontal: ${jsonData.tamanhoSensorFrontal}`}</p>
                        </div>
                    )}
                </div>
            </div>

            <VariateSection jsonData={jsonData}/>

            <h2 style={styles.sectionTitle}>Entre em contato</h2>
            <p style={styles.description}>
                Não deixe que uma câmera danificada comprometa sua experiência com o {jsonData.name}. Agende um reparo com nossos especialistas na <strong>Digital Conte Florianópolis</strong> agora mesmo!
            </p>
            <div style={styles.containerwhatsapp}>
                <a
                    style={styles.whatsappButton}
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (typeof window.gtag === "function") {
                            window.gtag("event", `whatsapp_click_conserto_camera_${jsonData.deviceType}_${jsonData.modelName}`, {
                                event_category: "engagement",
                                event_label: location.pathname,
                                value: 1,
                            });
                        }
                    }}
                >
                    <i className="fab fa-whatsapp"></i> {t('header.button')}
                </a>
            </div>
        </div>
    );
};

export default ConsertarCamera;
