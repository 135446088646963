import React from "react";
import { Helmet } from "react-helmet";
import { FaBatteryFull, FaTools, FaShieldAlt, FaClock } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { urls} from "./config"
import {DeviceInfo} from "./dynamicRoutes"
import VariateSection from "./VariateSection"

const ConsertarBaterias =({ jsonData }: { jsonData: DeviceInfo }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dateLanc = format(new Date(jsonData.lancamento.split("/").reverse().join("-")), "PPP", { locale: ptBR });

    const styles = useResponsiveStyles();
    const whatsappLink = getCompleteWhatsAppNumber(t);

    return (
        <div style={styles.container}>
            <Helmet>
                <title>{`Troca de Bateria ${jsonData.deviceType} ${jsonData.name} | Especialistas em ${jsonData.marca} - Digital Conte Florianópolis`}</title>
                <meta
                    name="description"
                    content={`Especialistas em troca de bateria para ${jsonData.name}. Peças originais, técnicos certificados e serviço garantido. Atendemos em Florianópolis. Agende agora mesmo.`}
                />
                <meta
                    name="keywords"
                    content={`troca de bateria, conserto de bateria, reparo de bateria, ${jsonData.name}, ${jsonData.marca}, bateria original, Florianópolis, assistência técnica especializada`}
                />
            </Helmet>

            <h1 style={styles.heading}>{`Troca de Bateria ${jsonData.deviceType} ${jsonData.name}`}</h1>
            <Link to={`/pt${urls.marca}${jsonData.marca.toLowerCase()}`} style={styles.backButton}>
                {t("backButton", `← Voltar para a lista de da marca ${jsonData.marca.toLowerCase()}`)}
            </Link>
            <div style={styles.containerwhatsapp}>
                <a
                    style={styles.whatsappButton}
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (typeof window.gtag === "function") {
                            window.gtag("event", `whatsapp_click_troca_bateria_${jsonData.deviceType}_${jsonData.modelName}`, {
                                event_category: "engagement",
                                event_label: location.pathname,
                                value: 1,
                            });
                        }
                    }}
                >
                    <i className="fab fa-whatsapp"></i> {t('header.button')}
                </a>
            </div>

            <p style={styles.description}>
                O <strong>{jsonData.name}</strong> é um dos dispositivos mais icônicos da {jsonData.marca}, combinando design premium e funcionalidades avançadas. 
                Lançado em {dateLanc}, este dispositivo revolucionou o mercado com sua tecnologia de carregamento sem fio ({jsonData.cargaSemFio}) e um desempenho otimizado, 
                graças à bateria {jsonData.bateriaTipo} de {jsonData.bateriaCapacidade}. 
                No entanto, como todas as baterias, ela pode degradar com o tempo, impactando sua experiência de uso.
            </p>

            <p style={styles.description}>
                Na <strong>Digital Conte Florianópolis</strong>, somos especialistas em diagnósticos e substituições de bateria para garantir que seu {jsonData.name} volte a operar com desempenho máximo. Utilizamos apenas peças originais e equipamentos modernos, assegurando que o serviço seja realizado com precisão 
                e qualidade incomparáveis.
            </p>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>Por que trocar a bateria do seu {jsonData.name}?</h2>
                <p style={styles.description}>
                    A bateria do seu dispositivo é responsável por sua autonomia e confiabilidade. Se você percebeu que ela está descarregando rapidamente, superaquecendo 
                    ou se o dispositivo está desligando inesperadamente, é hora de realizar a troca. Nossa equipe é treinada para avaliar e corrigir esses problemas com eficiência.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>Especificações da Bateria</h2>
                <p style={styles.description}>
                    Conheça as principais especificações da bateria do {jsonData.name} para entender por que nossos serviços garantem total compatibilidade e durabilidade:
                </p>
                <div style={styles.grid}>
                    {jsonData.bateriaTipo && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Tipo: ${jsonData.bateriaTipo}`}</p>
                        </div>
                    )}
                    {jsonData.bateriaCapacidade && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Capacidade: ${jsonData.bateriaCapacidade}`}</p>
                        </div>
                    )}
                    {jsonData.carregadorWatts && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Carregador: ${jsonData.carregadorWatts} Watts`}</p>
                        </div>
                    )}
                    {jsonData.cargaSemFio && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Carregamento sem fio: ${jsonData.cargaSemFio}`}</p>
                        </div>
                    )}
                    {jsonData.autonomiaConversacao && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Autonomia de conversação: ${jsonData.autonomiaConversacao}`}</p>
                        </div>
                    )}
                    {jsonData.autonomiaStandby && (
                        <div style={styles.differential}>
                            <FaBatteryFull style={styles.differentialIcon} />
                            <p style={styles.differentialText}>{`Autonomia em standby: ${jsonData.autonomiaStandby}`}</p>
                        </div>
                    )}
                </div>
            </div>

            < VariateSection jsonData={jsonData} />

            <h2 style={styles.sectionTitle}>Entre em contato</h2>
            <p style={styles.description}>
                Não comprometa o desempenho do seu {jsonData.name} com uma bateria desgastada. Na <strong>Digital Conte Florianópolis</strong>, oferecemos um atendimento 
                especializado para devolver a performance que você merece. Agende sua troca de bateria agora mesmo!
            </p>
            <div style={styles.containerwhatsapp}>
                <a
                    style={styles.whatsappButton}
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (typeof window.gtag === "function") {
                            window.gtag("event", `whatsapp_click_troca_bateria_${jsonData.deviceType}_${jsonData.modelName}`, {
                                event_category: "engagement",
                                event_label: location.pathname,
                                value: 1,
                            });
                        }
                    }}
                >
                    <i className="fab fa-whatsapp"></i> {t('header.button')}
                </a>
            </div>
        </div>
    );
};

export default ConsertarBaterias;
