import React from "react";
import { useLocation } from "react-router-dom";
import { FaTools, FaShieldAlt, FaClock } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import sections from "./variations.json";

const iconMap = {
  FaTools: FaTools,
  FaShieldAlt: FaShieldAlt,
  FaClock: FaClock,
};

const calculateHash = (input, offset) => {
  return Math.abs(
    input.split("").reduce((acc, char) => acc + char.charCodeAt(0) + offset, 0)
  );
};

const VariateSection = ({ jsonData }) => {
  const styles = useResponsiveStyles();
  const location = useLocation();
  const pathname = location.pathname;

  const section = sections[0];

  // Calculate individual hashes
  const descriptionHash = calculateHash(pathname, 0);
  const toolsHash = calculateHash(pathname, 1);
  const shieldAltHash = calculateHash(pathname, 2);
  const clockHash = calculateHash(pathname, 3);

  // Select deterministic variations
  const description = section.description[descriptionHash % section.description.length];
  const faTools = section.FaTools[toolsHash % section.FaTools.length];
  const faShieldAlt = section.FaShieldAlt[shieldAltHash % section.FaShieldAlt.length];
  const faClock = section.FaClock[clockHash % section.FaClock.length];

  return (
    <div style={styles.section}>
      <h2 style={styles.sectionTitle}>{section.title}</h2>
      <p style={styles.description}>{description.replace("{marca}", jsonData.marca)}</p>
      <div style={styles.grid}>
        <div style={styles.differential}>
          <FaTools style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faTools}</p>
        </div>
        <div style={styles.differential}>
          <FaShieldAlt style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faShieldAlt}</p>
        </div>
        <div style={styles.differential}>
          <FaClock style={styles.differentialIcon} />
          <p style={styles.differentialText}>{faClock}</p>
        </div>
      </div>
    </div>
  );
};

export default VariateSection;
