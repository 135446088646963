import React, {useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import { FloatingWhatsApp } from "@carlos8a/react-whatsapp-floating-button";
import logo from "./assets/images/logo-preto-fundo-branco.png";
import { WHATSAPP_NUMBER } from "./config";
import { useResponsiveStyles } from "./Styles";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const styles = useResponsiveStyles();
  const feedbackRef = useRef(null);
  const thisPage = location.pathname.split('/').filter(Boolean).slice(-1)[0];


  const getPath = () => `${window.location.origin}${location.pathname}`;
  const getWhatsAppMessage = (t) => `${t("whatsapp.initialMessageByClient")}${getPath()}`;
  const getCompleteWhatsAppNumber = () => `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${getWhatsAppMessage(t)}`;

  const trackEvent = (action, category, label, value = 1) => {
    if (typeof window.gtag === "function") {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value,
      });
    }
  };
  useEffect(() => {
    const loadFeedbackScript = () => {
      const script = document.createElement("script");
      script.src = "https://featurable.com/assets/bundle.js";
      script.defer = true;
      script.charset = "UTF-8";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadFeedbackScript();
          observer.disconnect(); // Stop observing once loaded
        }
      },
      { rootMargin: "100px" }
    );

    if (feedbackRef.current) {
      observer.observe(feedbackRef.current);
    }
  }, []);


  return (
    <div>
      {/* Main Footer Section */}
      <div  ref={feedbackRef}>
          <h2 style={styles.title}>{t("feedback.title")}</h2>
          <div
            id="featurable-1b249033-2abd-4b2a-918e-0679179fb128"
            data-featurable-async
          ></div>
        </div>
        <div  >
          <h2 style={styles.title}>{t("location.title")}</h2>
          <iframe
            src="https://maps.google.com/maps?q=Digital%20Conte%20Reparo%20Florianopolis&amp;t=m&amp;z=16&amp;output=embed&amp"
            style={styles.iframe}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Digital Conte Location"
          ></iframe>
        </div>
      <div style={styles.footerContainer}>
      <p style={styles.address}><i className="fas fa-map-marker-alt" style={styles.contactInfoIcon}></i>
          {t(
            "header.address",
            "R. Araújo Figueiredo, 46 - Loja 5 - Centro, Florianópolis - SC, 88010-520"
          )}
        </p>
        <p style={styles.contact}> <i className="far fa-clock" style={styles.contactInfoIcon}></i>{' '}
          {t(
            "header.hours",
            "Atendimento: Segunda a Sexta das 07:00 às 19:00 e Sábado das 07:00 às 13:00"
          )}
        </p>
        <div>
          <img src={logo} alt="Digital Conte" style={styles.footerImage} />
        </div>
        <div style={styles.footerInfo}>
          <p>{t("footer.copyright")}</p>
        </div>
        <FloatingWhatsApp
          phoneNumber="4899089562"
          accountName={t("whatsapp.accountName")}
          avatar={logo}
          statusMessage={t("whatsapp.statusMessage")}
          initialMessageByServer={t("whatsapp.chatMessage")}
          startChatText={t("header.button")}
          notification
          allowClickAway
          initialMessageByClient={getWhatsAppMessage(t)}
          onClick={() => trackEvent(`floating_whatsapp_click_${thisPage}`, "engagement", location.pathname)}
        />
      </div>

      {/* Dynamic Footer with Social Icons */}
      <div
        style={{
          ...styles.dynamicFooter,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <div style={styles.socialIconsContainer}>
          <a
            href="https://www.instagram.com/digitalcontefloripa/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent(`instagram_click_footer_${thisPage}`, "engagement", location.pathname)}
          >
            <i className="fab fa-instagram" style={styles.icon}></i>
          </a>
          <a
            href="https://maps.google.com/maps?q=Digital%20Conte%20Reparo%20Florianopolis"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent(`google_maps_click_footer_${thisPage}`, "engagement", location.pathname)}
          >
            <i className="fas fa-map-marker-alt" style={styles.icon}></i>
          </a>
          <a
            href={getCompleteWhatsAppNumber()}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent(`whatsapp_click_footer_${thisPage}`, "engagement", location.pathname)}
          >
            <i className="fab fa-whatsapp" style={styles.icon}></i>
          </a>
          <a
            href="https://linktr.ee/digitalconteofc"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent(`linktree_click_footer_${thisPage}`, "engagement", location.pathname)}
          >
            <i className="fas fa-link" style={styles.icon}></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
