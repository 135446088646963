import React from "react";
import { Helmet } from "react-helmet";
import { FaTools, FaShieldAlt, FaClock, FaCheckCircle } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { urls } from "./config";
import { DeviceInfo } from "./dynamicRoutes";
import  VariateSection from "./VariateSection"

const TrocarVidro = ({ jsonData }: { jsonData: DeviceInfo }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dateLanc = format(new Date(jsonData.lancamento.split("/").reverse().join("-")), "PPP", { locale: ptBR });

  const styles = useResponsiveStyles();
  const whatsappLink = getCompleteWhatsAppNumber(t);

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{`Troca de Vidro ${jsonData.deviceType} ${jsonData.name} | Especialistas em ${jsonData.marca} - Digital Conte Florianópolis`}</title>
        <meta
          name="description"
          content={`Troca profissional de vidro frontal ou traseiro para o seu ${jsonData.name}. Técnicos certificados, peças originais e garantia de qualidade. Agende agora.`}
        />
        <meta
          name="keywords"
          content={`troca de vidro, conserto de vidro, reparo de vidro, ${jsonData.name}, ${jsonData.marca}, vidro frontal, vidro traseiro, assistência técnica`}
        />
      </Helmet>

      <h1 style={styles.heading}>{`Troca de Vidro ${jsonData.deviceType} ${jsonData.name}`}</h1>
      <Link to={`/pt${urls.marca}${jsonData.marca.toLowerCase()}`} style={styles.backButton}>
        {t("backButton", `← Voltar para a lista da marca ${jsonData.marca.toLowerCase()}`)}
      </Link>
      <div style={styles.containerwhatsapp}>
        <a
          style={styles.whatsappButton}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (typeof window.gtag === "function") {
              window.gtag("event", `whatsapp_click_trocar_vidro_${jsonData.deviceType}_${jsonData.modelName}`, {
                event_category: "engagement",
                event_label: location.pathname,
                value: 1,
              });
            }
          }}
        >
          <i className="fab fa-whatsapp"></i> {t("header.button")}
        </a>
      </div>

      <p style={styles.description}>
        Bem-vindo à <strong>Digital Conte Florianópolis</strong>, sua melhor escolha para a troca de vidro frontal ou traseiro do seu {jsonData.name}. 
        Lançado em {dateLanc} pela {jsonData.marca}, este dispositivo combina design sofisticado e tecnologia avançada. 
        Porém, sabemos que um vidro quebrado pode comprometer tanto a aparência quanto a funcionalidade do aparelho.
      </p>

      <p style={styles.description}>
        Nossa equipe de técnicos certificados está preparada para substituir o vidro do seu dispositivo com precisão, seja na parte frontal ou traseira. 
        Utilizamos apenas peças originais para garantir que seu {jsonData.name} mantenha a qualidade e durabilidade.
      </p>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Por que substituir o vidro do seu {jsonData.name}?</h2>
        <p style={styles.description}>
          Um vidro quebrado pode expor o dispositivo a danos maiores, como infiltração de líquidos ou poeira. Além disso, a integridade estrutural do aparelho pode ser comprometida. 
          Garantimos uma substituição que restaura a estética e protege o seu dispositivo.
        </p>
      </div>

      <VariateSection jsonData={jsonData} />

      <h2 style={styles.sectionTitle}>Entre em contato</h2>
      <p style={styles.description}>
        Não deixe que um vidro quebrado atrapalhe o uso do seu {jsonData.name}. Agende agora mesmo a troca com nossos especialistas e recupere a estética e a funcionalidade do seu aparelho.
      </p>
      <div style={styles.containerwhatsapp}>
        <a
          style={styles.whatsappButton}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (typeof window.gtag === "function") {
              window.gtag("event", `whatsapp_click_trocar_vidro_${jsonData.deviceType}_${jsonData.modelName}`, {
                event_category: "engagement",
                event_label: location.pathname,
                value: 1,
              });
            }
          }}
        >
          <i className="fab fa-whatsapp"></i> {t("header.button")}
        </a>
      </div>
    </div>
  );
};

export default TrocarVidro;
