import React from "react";
import { Helmet } from "react-helmet";
import { FaWater, FaTools, FaShieldAlt, FaPhoneAlt } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { urls } from "./config";
import { DeviceInfo } from "./dynamicRoutes";
import VariateSection from "./VariateSection";

const ConsertarDispositivoMolhado = ({ jsonData }: { jsonData: DeviceInfo }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const styles = useResponsiveStyles();
  const whatsappLink = getCompleteWhatsAppNumber(t);

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{`Conserto de Dispositivo Molhado ${jsonData.deviceType} ${jsonData.name} | Especialistas em ${jsonData.marca} - Digital Conte Florianópolis`}</title>
        <meta
          name="description"
          content={`Especialistas no conserto de dispositivos molhados como o ${jsonData.name}. Serviço garantido e diagnóstico rápido em Florianópolis. Agende já!`}
        />
        <meta
          name="keywords"
          content={`dispositivo molhado, conserto de celular molhado, reparo de água, ${jsonData.name}, ${jsonData.marca}, assistência técnica especializada, Florianópolis`}
        />
      </Helmet>

      <h1 style={styles.heading}>{`Conserto de Dispositivo Molhado ${jsonData.deviceType} ${jsonData.name}`}</h1>
      <Link to={`/pt${urls.marca}${jsonData.marca.toLowerCase()}`} style={styles.backButton}>
        {t("backButton", `← Voltar para a lista de da marca ${jsonData.marca.toLowerCase()}`)}
      </Link>

      <div style={styles.containerwhatsapp}>
        <a
          style={styles.whatsappButton}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> {t("header.button")}
        </a>
      </div>

      <p style={styles.description}>
        Dispositivos eletrônicos como o <strong>{jsonData.name}</strong> são extremamente sensíveis à água. Seja um derramamento acidental ou uma queda na piscina, 
        a exposição à água pode causar danos permanentes se não for tratada rapidamente. Na <strong>Digital Conte Florianópolis</strong>, somos especialistas em
        diagnosticar e reparar dispositivos danificados pela água, devolvendo a funcionalidade que você precisa.
      </p>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Primeiros Socorros para um Dispositivo Molhado</h2>
        <ul style={styles.list}>
          <li>
            <FaWater style={styles.listIcon} /> <strong>Desligue o dispositivo imediatamente:</strong> Isso pode evitar curtos-circuitos.
          </li>
          <li>
            <FaWater style={styles.listIcon} /> <strong>Remova a bateria (se possível):</strong> Se o dispositivo permite, remova a bateria para limitar os danos.
          </li>
          <li>
            <FaWater style={styles.listIcon} /> <strong>Seque o máximo de água:</strong> Use um pano seco e limpo para remover a água superficial.
          </li>
          <li>
            <FaWater style={styles.listIcon} /> <strong>Não use secador de cabelo:</strong> O calor pode danificar os componentes internos.
          </li>
          <li>
            <FaWater style={styles.listIcon} /> <strong>Procure ajuda profissional:</strong> Leve o dispositivo para uma assistência técnica especializada como a nossa.
          </li>
        </ul>
      </div>

      <VariateSection jsonData={jsonData} />

      <h2 style={styles.sectionTitle}>Entre em contato</h2>
      <p style={styles.description}>
        Não deixe a água destruir o seu {jsonData.name}. Entre em contato com a <strong>Digital Conte Florianópolis</strong> para um diagnóstico rápido e
        reparo garantido. Agende seu atendimento agora mesmo!
      </p>
      <div style={styles.containerwhatsapp}>
        <a
          style={styles.whatsappButton}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> {t("header.button")}
        </a>
      </div>
    </div>
  );
};

export default ConsertarDispositivoMolhado;
