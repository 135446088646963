import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import i18n from "./i18n";
import TopBar from "./Topbar";
import EmptyTopBar from "./EmptyTopBar";
import Footer from "./Footer";
import Layout from "./Layout"
import ConsertarTelas from "./ConsertarTelas";
import ConsertarBaterias from "./ConsertarBaterias";
import Campaign from "./Campaign";
// import Campaign2 from "./Campaign2";
import {EspecialistaMarca} from "./EspecialistaMarca";
import { generateRoutesFromFiles, DefautRouteType } from "./dynamicRoutes";
import { urls } from "./config";
import Portfolio from "./Portfolio";
import ConsertarCamera from "./ConsertarCamera";
import TrocarVidro from "./TrocarVidro";
import ConsertarDispositivoMolhado from "./ConsertarDispositivoMolhado";
import ConsertarTudo from "./ConsertarTudo"
import Os from "./Os"; // Import Os component
import osData from "./assets/info/os.json"; // Import the JSON file
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEGetEiEnYF3m59gHzcIr3fxnwT48OC3I",
  authDomain: "digitalconte-prd.firebaseapp.com",
  projectId: "digitalconte-prd",
  storageBucket: "digitalconte-prd.firebasestorage.app",
  messagingSenderId: "133655702132",
  appId: "1:133655702132:web:baa422e01494942d097f21",
  measurementId: "G-12HHTKL4MS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Lazy loaded components
const Home = lazy(() => import("./Home"));
const Location = lazy(() => import("./Location"));
const About = lazy(() => import("./About"));
const Blog = lazy(() => import("./Blog"));
const Login = lazy(() => import("./Login"));
const Post = lazy(() => import("./Post"));

const dynamicRoutesTela = generateRoutesFromFiles();

const App = () => {
  i18n.changeLanguage("pt");
  const home = `/pt${urls.home}`;

  // Extract unique brands from dynamicRoutesTela
  const uniqueBrands = Array.from(
    new Set(dynamicRoutesTela.map(({ data }) => data.brand?.toLowerCase()))
  ).filter(Boolean);

  return (
    <Router>
      <Routes>
      <Route path="/" element={
          <Campaign customTexts={{
        "header.title": "Digital Conte - Assistência Técnica",
        "header.description": "Está Caro Consertar o Celular? Por que é Mais Caro Consertar um Celular do que Comprar um Novo? Mude Essa Realidade",
      }} />
    }/>
        
        <Route path="/pt/campanhas/1" element={
          <Campaign customTexts={{
        "header.title": "Retiramos e Entregamos",
        "header.description": "Garantia nos serviços. Facilidade de pagamento. Atendimento Via WhatsApp, Rápido e Prático",
      }} />
    }/>
        <Route path="/pt/campanhas/2" element={
          <Campaign customTexts={{
            "header.title": "Orçamento Gratuito Aqui",
            "header.description": "Especialista no Whatsapp, estamos Online, agende já",
          }} />
    }/>
        {osData.map(({ url, ...osDetails }, index) => (
          <Route
            key={`os-${index}`}
            path={url}
            element={
              <TopBar disable={true}>
                <EmptyTopBar />
                <Layout>
                  <Os jsonData={osDetails} />
                </Layout>
              </TopBar>
            }
          />
        ))}
        
        {dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path}`}
            path={path}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <ConsertarTelas jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}

{dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path.replace(DefautRouteType, "trocar-bateria")}`}
            path={path.replace(DefautRouteType, "trocar-bateria")}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <ConsertarBaterias jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}


{dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path.replace(DefautRouteType, "vidro-quebrado")}`}
            path={path.replace(DefautRouteType, "vidro-quebrado")}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <TrocarVidro jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}

{dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path.replace(DefautRouteType, "molhou")}`}
            path={path.replace(DefautRouteType, "molhou")}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <ConsertarDispositivoMolhado jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}

{dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path.replace(DefautRouteType, "trocar-camera")}`}
            path={path.replace(DefautRouteType, "trocar-camera")}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <ConsertarCamera jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}

      
{dynamicRoutesTela.map(({ path, data }) => (
        <Route
            key={`${path.replace(DefautRouteType, "todos-problemas")}`}
            path={path.replace(DefautRouteType, "todos-problemas")}
            element={
              <TopBar disable={true}>
        <EmptyTopBar />
        <Layout>
                <ConsertarTudo jsonData={data} />
                </Layout>
          </TopBar>
            }
            
          />
             
          
        ))}
          
          

        {/* Language-Specific Routes */}
        <Route
          path="/:lang/*"
          element={
            <Suspense fallback={<div>Loading...</div>}>
               
              <TopBar>
                <EmptyTopBar />
                <Layout>
                <Routes>
                {uniqueBrands.map((brand) => (
          <Route
            key={brand}
            path={`${urls.marca}${brand}`}
            element={
                <EspecialistaMarca brand={brand} />
            }
          />
        ))}
         {/* Dynamic Routes for Specific Devices */}
        
             
      
                  <Route path={urls.home} element={<Home />} />
                  <Route path={urls.about} element={<About />} />
                  <Route path={urls.blog} element={<Blog />} />
                  <Route path={urls.blogPost} element={<Post />} />
                  {/* <Route path={urls.courses} element={<Courses />} /> */}
                  <Route path={urls.location} element={<Location />} />
                  <Route path={urls.login} element={<Login />} />
                  <Route path={urls.portfolio} element={<Portfolio />} />
                  <Route path="*" element={<Navigate to={home} />} />
                </Routes>
                </Layout>
              </TopBar>
            </Suspense>
          }
        />

        {/* Redirect any unmatched route to home */}
        <Route path="*" element={<Navigate to={home} />} />
      </Routes>
    </Router>
  );
};

export default App;
