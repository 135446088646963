import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import helmetData from "./assets/helmetData.json";
import { currentLanguage, availableLanguages } from "./config";


const HelmetSummary = () => {
  const location = useLocation(); // Get the current location object
  const currentPath = location.pathname; // Extract the pathname from location
  const pathWithoutLang = currentPath.split("/").slice(2).join("/"); // Remove the language segment

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: currentLanguage(),
        }}
      >
        <title>{helmetData.title}</title>
        {helmetData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.name ? { name: meta.name } : { property: meta.property })}
            content={meta.content}
          />
        ))}
        <meta itemProp="sameAs" content="https://www.instagram.com/digitalcontefloripa/" />
        {availableLanguages.map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hreflang={lang}
            href={`${window.location.origin}/${lang}/${pathWithoutLang}`}
          />
        ))}
      </Helmet>
    </div>
  );
};

export default HelmetSummary;
