import { useMediaQuery } from "react-responsive";


const staticFooter =  {
    bottom: 0,
    left: "50%",
    color: "#333",
    // backgroundColor: "#e6ab41",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.5)",
    // zIndex: 1000,
  };
const   section= {
    marginBottom: "10px",
    textAlign: "center",
    padding: "50px 30px",
  }
const stylesDesktop = {
      postBox: {
        padding: "20px",
        borderRadius: "15px",
        backgroundColor: "#f7f7f7",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      },
      backButton: {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          textDecoration: "none",
          color: "#e6ab41",
          fontWeight: "bold",
          fontSize: "1rem",
          marginBottom: "20px",
          cursor: "pointer",
        },
        backIcon: {
          fontSize: "1rem",
        },
      date: {
        fontSize: "1rem",
        color: "#777",
        marginBottom: "20px",
      },
      caption: {
        fontSize: "1rem",
        lineHeight: "1.8",
        color: "#333",
        whiteSpace: "pre-wrap",
        marginBottom: "20px",
      },
      shareIcons: {
        display: "flex",
        gap: "10px",
        marginTop: "20px",
      },
    topBar: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor: "#e6ab41",
        color: "white",
        padding: "20px 20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      languageSwitcher: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
      },
      flag: {
        width: "32px",
        height: "20px",
        cursor: "pointer",
        borderRadius: "3px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
      },
      navContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      },
      link: {
        color: "white",
        textDecoration: "none",
        margin: "0 20px",
        fontSize: "18px",
      },
    footerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // backgroundColor: "#f2f2f2",
        padding: "30px",
        marginBottom: "50px"
      },
      footerImage: {
        maxWidth: "200px",
        height: "auto",
        background: "none",
        border: "none",
        display: "block",
        margin: "0 auto",
      },
      footerInfo: {
        textAlign: "center",
        marginTop: "10px",
      },
      staticFooter: staticFooter,
      dynamicFooter: {
        ...staticFooter,
        position: "fixed",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#e6ab41",
        padding: "15px",
        borderRadius: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      },
      socialIconsContainer: {
        display: "flex",
        gap: "20px",
        color: "white",
        textDecoration: "none",
      },
      icon: {
        color: "white",
        fontSize: "30px",
        cursor: "pointer",
      },
    container: {
        maxWidth: "900px",
        margin: "40px auto",
        padding: "10px",
        fontFamily: "'Roboto', Arial, sans-serif",
      },
      heading: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#333",
        textAlign: "center",
        marginBottom: "20px",
        borderBottom: "2px solid #e6ab41",
        paddingBottom: "10px",
      },
      courseList: {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      },
      courseItem: {
        backgroundColor: "#f7f7f7",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      },
      courseTitle: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#333",
        marginBottom: "10px",
      },
      courseSummary: {
        fontSize: "1rem",
        lineHeight: "1.8",
        color: "#555",
        marginBottom: "20px",
      },
      moduleList: {
        fontSize: "1rem",
        color: "#444",
        marginBottom: "20px",
        listStyleType: "disc",
        paddingLeft: "20px",
      },
      courseLink: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        fontWeight: "bold",
        color: "white",
        backgroundColor: "#e6ab41",
        padding: "10px 15px",
        borderRadius: "10px",
        fontSize: "1rem",
        gap: "8px",
      },
    blogContainer: {
        maxWidth: "900px",
        margin: "40px auto",
        padding: "20px",
        fontFamily: "'Roboto', Arial, sans-serif",
      },
      whiteSection: {
        ...section,
        backgroundColor: '#ffffff',
      },
      graySection: {
        ...section,
        backgroundColor: '#f2f2f2',
      },
      title: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "20px",
        paddingBottom: "10px",
      },
      intro: {
        fontSize: "1rem",
        color: "#555",
        textAlign: "center",
        marginBottom: "30px",
      },
      socialMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        color: "#e6ab41",
        fontSize: "1rem",
        marginBottom: "20px",
      },
      postContainer: {
        marginBottom: "30px",
        padding: "20px",
        borderRadius: "15px",
        backgroundColor: "#f7f7f7",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      },
      iconBox: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40px", // Box dimensions
        height: "40px",
        border: "1px solid #ddd", // Border around the box
        borderRadius: "5px", // Optional: Rounded corners
        backgroundColor: "#f9f9f9", // Optional: Background color
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Shadow for depth
        margin: "5px", // Space around the box
      },
      postTitle: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#333",
        marginBottom: "10px",
      },
      postDate: {
        fontSize: "0.9rem",
        color: "#666",
        marginBottom: "10px",
      },
      postContent: {
        fontSize: "1rem",
        lineHeight: "1.8",
        color: "#333",
        whiteSpace: "pre-wrap",
        marginBottom: "20px",
      },
      actionButtons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      },
      platformButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        fontWeight: "bold",
        color: "white",
        padding: "10px 15px",
        borderRadius: "10px",
        fontSize: "1.2rem",
      },
      nativeShareButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#e6ab41",
        color: "white",
        padding: "10px 15px",
        borderRadius: "10px",
        fontWeight: "bold",
        fontSize: ".5rem",
        margin: "20px 0",
        cursor: "pointer",
        gap: "8px",
        border: "1px solid white", // Adds a line surrounding the button
        // boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)", // Optional shadow effect
        // transition: "transform 0.2s, box-shadow 0.2s", // Smooth interaction effect
        textDecoration: "none", // Ensure consistent style for Link
      },
      platformIcon: {
        // marginRight: "8px",
        fontSize: "1rem",
      },
  contactInfoIcon: {
    marginRight: "10px",
  },
  containerwhatsapp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  whatsappButton: {
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#25d366",
    color: "white",
    width: "50vh",
    textDecoration: "none",
    borderRadius: "25px",
    fontWeight: "bold",
    fontSize: "1rem",
    textAlign: "center",
  },

  description: {
    fontSize: "1rem",
    color: "#555",
    textAlign: "justify",
    marginBottom: "30px",
    whiteSpace: "pre-wrap",
  },

  sectionTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#333",
    borderBottom: "2px solid #e6ab41",
    paddingBottom: "5px",
  },
  differential: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#f7f7f7",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    flexDirection: "column",
  },
  differentialIcon: {
    fontSize: "1.5rem",
    color: "#e6ab41",
  },
  differentialText: {
    fontSize: "1rem",
    color: "#333",
  },
  address: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#333",
    marginTop: "20px",
    textAlign: "center",
  },
  contact: {
    fontSize: "1rem",
    marginTop: "20px",
    color: "#555",
    textAlign: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  headerImage: {
    backgroundImage: `url(${require("./assets/images/img1.avif")})`,
    display: "flex",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "60%",
    height: "100%",
    transform: "scaleX(-1)",
  },

  welcomeText: {
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    // fontSize: "1rem",
  },
  welcomeTextH1: {
    fontSize: "2rem",
    color: "#e6ab41",
    marginBlockStart: "0",
    marginBlockEnd: "0",
    marginInlineStart: "0",
    marginInlineEnd: "0px",
  },
  welcomeTextH2: {
    fontSize: "1rem",
  },
  welcomeTextP: {
    fontSize: "1rem",
    margin: "20px 0",
  },
  footer: {
    padding: "30px",
  },
  iframe: {
    border: 0,
    width: "100%",
    height: "450px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    gap: "0px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    background: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  },
  cardHover: {
    transform: "translateY(-10px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
  iconCard: {
    fontSize: "1rem",
    color: "rgb(230, 171, 65)",
    marginBottom: "15px",
    transition: "color 0.3s ease, transform 0.3s ease",
  },
  iconHover: {
    color: "#25d366",
    transform: "rotate(10deg)",
  },
  label: {
    fontSize: "1rem",
    color: "rgb(230, 171, 65)",
  },
};

const stylesPhone = {
  ...stylesDesktop,
  header: { ...stylesDesktop.header, flexDirection: "column" },
  welcomeText: {
    ...stylesDesktop.welcomeText,
    // width: "100%",
  },
  section: {
    ...section,
    marginTop: "10px",
  },
  headerImage: {
    ...stylesDesktop.headerImage,
    width: "100%",
    // height: "auto",
    transform: "none",
  },
};

export const useResponsiveStyles = (): stylesPhone => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  return isTabletOrMobile ? stylesPhone : stylesDesktop;
};
