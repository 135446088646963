import React from "react";
import { Helmet } from "react-helmet";
import { FaTools, FaShieldAlt, FaClock, FaCheckCircle, FaPhoneAlt } from "react-icons/fa";
import { useResponsiveStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { getCompleteWhatsAppNumber } from "./config";
import { useLocation, Link } from "react-router-dom";
import { urls } from "./config";
import { DeviceInfo } from "./dynamicRoutes";
import VariateSection from "./VariateSection"

const OutrosConsertos = ({ jsonData }: { jsonData: DeviceInfo }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const styles = useResponsiveStyles();
  const whatsappLink = getCompleteWhatsAppNumber(t);

  const commonIssues = [
    "Tela trincada ou quebrada",
    "Bateria com baixa duração",
    "Porta de carregamento danificada",
    "Problemas com o botão de energia",
    "Câmera não funcionando ou desfocada",
    "Alto-falante ou microfone com falhas",
    "Dispositivo superaquecendo",
    "Conexão Wi-Fi ou Bluetooth instável",
    "Software travando ou lento",
    "Entrada para cartão SIM ou SD danificada"
  ];

  const tips = {
    "Tela trincada ou quebrada": "Evite pressionar a tela e procure assistência técnica imediatamente.",
    "Bateria com baixa duração": "Evite usar carregadores não originais e monitore o uso de aplicativos que consomem muita energia.",
    "Porta de carregamento danificada": "Não force o cabo de carregamento e mantenha a porta limpa e livre de sujeira.",
    "Problemas com o botão de energia": "Tente usar funções de software para substituir o botão temporariamente.",
    "Câmera não funcionando ou desfocada": "Verifique se há sujeira nas lentes e evite utilizar força excessiva no dispositivo.",
    "Alto-falante ou microfone com falhas": "Mantenha os componentes secos e limpos; evite exposições à umidade.",
    "Dispositivo superaquecendo": "Desligue o dispositivo e deixe-o esfriar antes de utilizá-lo novamente.",
    "Conexão Wi-Fi ou Bluetooth instável": "Reinicie o dispositivo e verifique se as configurações estão corretas.",
    "Software travando ou lento": "Atualize o sistema operacional e remova aplicativos desnecessários.",
    "Entrada para cartão SIM ou SD danificada": "Evite forçar a entrada e procure assistência para reparo especializado."
  };
  const shuffleArray = (array, hash) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = hash % (i + 1); // Deterministic shuffle
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const calculateHash = (input) =>
    input.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const hash = calculateHash(location.pathname);
  const shuffledIssues = shuffleArray(commonIssues, hash);

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{`Outros Consertos para ${jsonData.name} | Digital Conte Florianópolis`}</title>
        <meta
          name="description"
          content={`Diagnóstico e reparo para ${jsonData.name}. Especialistas em solucionar qualquer defeito no seu dispositivo ${jsonData.marca}. Entre em contato!`}
        />
        <meta
          name="keywords"
          content={`outros consertos, ${jsonData.name}, problemas comuns, reparo de dispositivos, assistência técnica, Digital Conte Florianópolis`}
        />
      </Helmet>

      <h1 style={styles.heading}>{`Outros Consertos para ${jsonData.name}`}</h1>
      <Link to={`/pt${urls.portfolio}`} style={styles.backButton}>
        {t("backButton", `← Voltar para a lista de serviços`)}
      </Link>
      < Contato jsonData={jsonData} />
      <p style={styles.description}>
        O <strong>{jsonData.name}</strong>, lançado em {jsonData.lancamento}, é um {jsonData.deviceType} da {jsonData.marca} que se destaca por suas especificações avançadas, como a tela {jsonData.telaTipo}, bateria de {jsonData.bateriaCapacidade}, e câmeras de alta resolução. 
        No entanto, até mesmo dispositivos de ponta podem enfrentar problemas ao longo do tempo. Aqui estão os defeitos mais comuns que consertamos para este modelo:
      </p>

      <ul style={styles.list}>
        {shuffledIssues.map((issue, index) => (
          <li key={index} style={styles.listItem}>
            <FaCheckCircle style={styles.listIcon} /> {issue}
          </li>
        ))}
      </ul>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Dicas de Primeiros Socorros</h2>
        {shuffledIssues.map((issue, index) => (
          <div key={index} style={styles.differential}>
            <h3 style={styles.differentialText}>
              <FaTools style={styles.differentialIcon} /> {issue}
            </h3>
            <p style={styles.description}>{tips[issue]}</p>
          </div>
        ))}
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Por que esses problemas ocorrem?</h2>
        <p style={styles.description}>
          Devido às especificações avançadas do {jsonData.name}, como a tela {jsonData.telaTamanho} e a bateria {jsonData.bateriaTipo}, problemas podem surgir com o tempo por uso intensivo ou acidentes. 
          Por exemplo, a tela Super Retina OLED é altamente sensível a impactos, e o sistema de carregamento sem fio pode ser comprometido por poeira ou danos à porta de carregamento.
        </p>
        <p style={styles.description}>
          A Digital Conte Florianópolis está preparada para resolver qualquer problema, utilizando peças originais e técnicas avançadas de reparo.
        </p>
      </div>

      <VariateSection jsonData={jsonData} />
      <h2 style={styles.sectionTitle}>Entre em contato</h2>
      <p style={styles.description}>
        Independente do problema ou modelo do dispositivo, a <strong>Digital Conte Florianópolis</strong> está aqui para ajudar. Agende seu reparo agora mesmo e devolva a funcionalidade total ao seu {jsonData.name}!
      </p>
      <Contato jsonData={jsonData} />
      {/* </div> */}
    </div>
  );
};

const Contato = ({ jsonData }: { jsonData: DeviceInfo }) => {
  const styles = useResponsiveStyles();
  const { t } = useTranslation();
  const whatsappLink= getCompleteWhatsAppNumber(t);

  return (
    <div>
    
      <div style={styles.containerwhatsapp}>
        <a
          style={styles.whatsappButton}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> {t("header.button")}
        </a>
        </div>
        </div>
  )
}

export default OutrosConsertos;
