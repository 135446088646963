import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "./Styles";
import { generateRoutesFromFiles } from "./dynamicRoutes";
import { Link, useLocation, useParams } from "react-router-dom";
import {urls, getCompleteWhatsAppNumber} from "./config"
import { EachDevice } from "./EspecialistaMarca";

const filterRoutesByType = (routes, type) => {
  return routes.filter((route) => route.path.includes(`/${type}/`));
};

const groupByBrand = (routes) => {
  return routes.reduce((acc, route) => {
    const brand = route.data.brand ? route.data.brand.charAt(0).toUpperCase() + route.data.brand.slice(1) : "Outros";
    if (!acc[brand]) acc[brand] = [];
    acc[brand].push(route);
    return acc;
  }, {});
};

const Section = ({ title, groupedRoutes, styles, searchQuery }) => {
  const { language } = useParams();
const lang = language ?? "pt"; // Get language parameter from URL
  const { t } = useTranslation();
  const location = useLocation();

  const filteredGroups = Object.entries(groupedRoutes).filter(([brand, devices]) =>
    devices.some((device) =>
      device.data.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div style={styles.section}>
      <h2 style={styles.sectionTitle}>{title}</h2>
      <div style={styles.grid}>
        {filteredGroups.length > 0 ? (
          filteredGroups.map(([brand, devices]) => (
            <details key={brand} style={styles.dropdown}>
              <summary>
                <div style={styles.brandTitleWrapper}>
                  <Link
                    to={`/${lang}${urls.marca}${brand.toLowerCase()}`}
                    style={styles.brandTitle}
                  >
                    {brand}
                  </Link>
                </div>
              </summary>
              {/* <div style={styles.grid}></div> */}
              {
               
              devices
                .filter((device) =>
                  device.data.name?.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((route, index) => (
                  <EachDevice route={route} index={index} />
                  
                ))}
                {/* </div> */}
            </details>
          ))
        ) : (
          <div>
            <p style={styles.noResults}>
              {t("specialist.noResults", "Nenhum dispositivo encontrado para essa marca.")}
            </p>
            <div style={styles.containerwhatsapp}>
              <a
                style={styles.whatsappButton}
                href={getCompleteWhatsAppNumber(t)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (typeof window.gtag === "function") {
                    window.gtag("event", `whatsapp_click_troca_bateria_portfolio`, {
                      event_category: "engagement",
                      event_label: location.pathname,
                      value: 1,
                    });
                  }
                }}
              >
                <i className="fab fa-whatsapp"></i> {t("header.button")}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};



const Portfolio = () => {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();
 

  const [searchQuery, setSearchQuery] = useState("");

  const routes = generateRoutesFromFiles("tela-quebrada");
  // const routesBateria = generateRoutesFromFiles("trocar-bateria");

  const cellPhoneRoutes = filterRoutesByType(routes, "celular");
  const tabletRoutes = filterRoutesByType(routes, "tablet");
  const smartwatchRoutes = filterRoutesByType(routes, "smartwatch");

  const groupedCellPhones = groupByBrand(cellPhoneRoutes);
  const groupedTablets = groupByBrand(tabletRoutes);
  const groupedSmartwatches = groupByBrand(smartwatchRoutes);

  return (
    <div style={styles.container}>
      <Helmet>
        <meta
          name="description"
          content={t(
            "portfolio.description",
            "Explore nosso portfólio de conserto de celular, tablets e smartwatches."
          )}
        />
        <meta name="keywords" content="Digital Conte, portfólio, manutenção, celulares, tablets, smartwatches, assistência técnica" />
        <meta name="author" content="Digital Conte" />
      </Helmet>
      <h1 style={styles.heading}>{t("portfolio.title", "Portfólio")}</h1>
      <p style={styles.description}>
        {t(
          "portfolio.intro",
          "Confira os modelos de aparelhos que já passaram pela nossa manutenção. Use a barra de pesquisa para encontrar o que precisa."
        )}
      </p>
      <input
        type="text"
        placeholder={t("portfolio.searchPlaceholder", "Pesquisar aparelhos...")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={styles.searchBar}
      />
      <Section
        title={t("portfolio.cellphones", "Celulares")}
        groupedRoutes={groupedCellPhones}
        styles={styles}
        searchQuery={searchQuery}
      />
      <Section
        title={t("portfolio.tablets", "Tablets")}
        groupedRoutes={groupedTablets}
        styles={styles}
        searchQuery={searchQuery}
      />
      <Section
        title={t("portfolio.smartwatches", "Smartwatches ")}
        groupedRoutes={groupedSmartwatches}
        styles={styles}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default Portfolio;
