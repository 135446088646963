import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "./Styles";
import { generateRoutesFromFiles } from "./dynamicRoutes";
import { Link, useLocation } from "react-router-dom";
import { getCompleteWhatsAppNumber } from "./config";
import VariateSection from "./VariateSection"

const EachDevice = ({ route }, { index }) => {
  const styles = useResponsiveStyles();
  return (
    <div key={index} style={styles.card}>
      <div style={styles.deviceRow}>
        <span style={styles.deviceName}>{route.data.name || "Desconhecido"}</span>
        <div style={styles.iconLinksContainer}>
          <Link to={route.path} style={styles.iconLink}>
            <div style={styles.iconBox}>
              <i className="fas fa-mobile-alt" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "trocar-bateria")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-battery-full" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "trocar-camera")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-camera" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "vidro-quebrado")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-window-maximize" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "molhou")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-tint" style={styles.platformIcon}></i>
            </div>
          </Link>
          <Link
            to={route.path.replace("tela-quebrada", "todos-problemas")}
            style={styles.iconLink}
          >
            <div style={styles.iconBox}>
              <i className="fas fa-tools" style={styles.platformIcon}></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};


export default EachDevice;


const EspecialistaMarca = ({ brand }) => {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");

  const routes = generateRoutesFromFiles();
  const filteredDevices = routes.filter(
    (route) => route.data.brand?.toLowerCase() === brand?.toLowerCase()
  );
  const jsonData = {
    marca: {brand}
  }

  const filteredBySearch = filteredDevices.filter((device) =>
    device.data.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={styles.container}>
      <Helmet>
        <title>{`Especialistas em ${brand} | Digital Conte`}</title>
        <meta
          name="description"
          content={t(
            "specialist.description",
            `Digital Conte: Especialistas em troca de tela e manutenção de dispositivos da marca ${brand}. Garanta qualidade e segurança com garantia de até 12 meses.`
          )}
        />
        <meta
          name="keywords"
          content={`Digital Conte, troca de tela ${brand}, conserto ${brand}, conserto de celular ${brand}, reparo de tela, assistência técnica ${brand}`}
        />
        <meta name="author" content="Digital Conte" />
      </Helmet>
      <h1 style={styles.heading}>{`${t("specialist.heading", "Especialistas em")} ${brand}`}</h1>
      <p style={styles.description}>
        {t(
          "specialist.intro",
          `Se você está procurando um serviço confiável para consertar seu dispositivo ${brand}, você está no lugar certo. Na Digital Conte, combinamos experiência, qualidade e garantia para entregar o melhor serviço possível.`
        )}
      </p>
      <input
        type="text"
        placeholder={t("specialist.searchPlaceholder", "Pesquisar dispositivos...")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={styles.searchBar}
      />

      {filteredBySearch.length > 0 ? (
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>{t("specialist.devices", "Modelos Disponíveis")}</h2>
          <div style={styles.grid}>
            {filteredBySearch.map((route, index) => (
              <EachDevice route={route} index={index}/>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p style={styles.noResults}>
            {t("specialist.noResults", "Nenhum dispositivo encontrado para essa marca.")}
          </p>
          <div style={styles.containerwhatsapp}>
            <a
              style={styles.whatsappButton}
              href={getCompleteWhatsAppNumber(t)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (typeof window.gtag === "function") {
                  window.gtag("event", `whatsapp_click_conserto_tela_${brand}`, {
                    event_category: "engagement",
                    event_label: location.pathname,
                    value: 1,
                  });
                }
              }}
            >
              <i className="fab fa-whatsapp"></i> {t("header.button")}
            </a>
          </div>
        </div>
      )}
      
      <VariateSection jsonData={jsonData}/>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>{t("specialist.serviceProcess", "Como funciona nosso processo de reparo?")}</h2>
        <p style={styles.description}>
          {t(
            "specialist.process",
            "Na Digital Conte, todo o processo de reparo é transparente e rápido. Veja como funciona:"
          )}
        </p>
        <ol style={styles.list}>
          <li style={styles.listItem}>
            <strong>{t("specialist.step1", "Avaliação Detalhada")}</strong> – Realizamos um diagnóstico completo do problema da tela ou bateria.
          </li>
          <li style={styles.listItem}>
            <strong>{t("specialist.step2", "Reparo ou Substituição")}</strong> – Oferecemos a melhor solução, utilizando peças de qualidade.
          </li>
          <li style={styles.listItem}>
            <strong>{t("specialist.step3", "Garantia e Entrega Rápida")}</strong> – Garantimos o reparo no menor tempo possível, sem comprometer a qualidade.
          </li>
        </ol>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>{t("specialist.contactUs", "Entre em Contato")}</h2>
        <p style={styles.description}>
          {t(
            "specialist.contactDescription",
            `Pronto para consertar seu ${brand}? Entre em contato conosco agora mesmo e aproveite o melhor serviço de troca de tela e baterias da região.`
          )}
        </p>
      </div>
    </div>
  );
};

export {EspecialistaMarca , EachDevice};
